@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  /* width: 2px;
  height: 2px; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.previewContainer {
  position: relative;
  transition: all 0.2s;
  margin-top: 20px;
}

.previewContainer img {
  opacity: 1;
  width: auto;
  height: auto;
  max-width: 350px;
  max-height: 200px;
  border: 4px solid black;
  margin-right: 10px;
  cursor: pointer;
}

.previewContainer svg {
  color: #ffffffba;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 1;
}

.previewContainer svg:hover {
  color: #c6011fca;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiAutocomplete-popper{
  z-index: 11111 !important;
}

.inputClass{
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 16px 14px !important;
  border-radius: 10px;
  line-height: 24px !important;
}